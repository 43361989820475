<template>
  <div>
    <!-- pic397F1D4A52F1CE02DEAF75ECAE1F1FF9.jpg -->
    <!-- download2EDAD5A57C4136AF41DA87DBFB3AED29.flv -->
    <video class="rounded"
           src="@/assets/news-network/videos/chicken-dance.mp4"
           poster="@/assets/news-network/video-chicken-dance.jpg"
           onclick="this.paused ? this.play() : this.pause();">
    </video>
    <p>
      Chickens bob their heads, scratch the ground, flap their wings and strut around! Watch this LEGO Universe minifigure rock like a rooster!
    </p>
  </div>
</template>
